<template>
  <div>
    <b-alert show variant="danger">10개 이내에서 테스트해주세요</b-alert>
    <div class="mb-3">
      <b-textarea class="mb-1" v-model="goods_nos" rows="5"></b-textarea>
      <b-btn variant="success" @click="load()">Load</b-btn>
    </div>

    <div v-for="cf in cfs">
      <b-alert show variant="success">[{{cf.goods_no}}] {{cf.goods_nm}}</b-alert>
      <div v-for="img in cf.images" class="border-bottom">
        URL: <a :href="img.url" target="_blank">{{img.url}}</a>
        <div style="min-height:100px;background:#f0fff0">
          ORG:<br/>
          <div v-for="i in img.org" class="d-inline-block">
            <span>{{i.key}}
              <small v-if="i.dim">({{i.dim.width}} x {{i.dim.height}})</small>
            </span><br/>
            <a :href="i.value" target="_blank"><img :src="i.value" style="max-width:150px" /></a>
          </div>
        </div>
        <div style="min-height:100px;background:#fff0f0">
          ASIS:<br/>
          <div v-for="i in img.arr" class="d-inline-block">
            <span>{{i.key}}
              <small v-if="i.dim">({{i.dim.width}} x {{i.dim.height}})</small>
            </span><br/>
            <a :href="i.value" target="_blank"><img :src="i.value" style="max-width:150px" /></a>
          </div>
        </div>
        <div style="min-height:100px;background:#f0f0ff">
          TOBE:<br/>
          <div v-for="i in img.tobe" class="d-inline-block">
            <span>{{i.key}}
              <small v-if="i.dim">({{i.dim.width}} x {{i.dim.height}})</small>
            </span><br/>
            <a :href="i.value" target="_blank"><img :src="i.value" style="max-width:150px" /></a>
          </div>
          <b-spinner v-if="img.loading"></b-spinner>
        </div>
        <hr/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LambdaImageCheck',
  data() {
    return {
      goods_nos: `9952085`,
      cfs: [],
    }
  },
  async created() {
  },
  methods: {
    async load() {
      const goods_nos = this.goods_nos.trim().split(/\D+/g).filter(e => e).map(e => +e);
      let j = await this.$api.postJson('/goods/lambdaImage', {goods_nos});
      if (j) {
        this.cfs = j.cfs;
        j.cfs.forEach(cf => {
          cf.images.forEach((img, idx) => {
            img.loading = true;
            img.tobe = [];
            img.url = img.url || cf.original_img_urls[idx];
            this.$api.postJson('/goods/lambdaImageConvert', {url: img.url}).then(e => {
              img.loading = false;
              console.log('done', img, e);
              Object.entries(e.imageMap).filter(([k]) => !['ver', 'ratio', 'url'].includes(k)).forEach(([k, v]) => {
                img.tobe.push({key: `${k}`, value: `https://i.balaan.io/${v}`});
              });
              img.tobe.push({key: 'naver', value: `https://ep-image.balaan.io/${e.imageMap.square_200}?n=2`});
              img.tobe.forEach(i => this.getImgSize(i));
              this.$forceUpdate();
            }).catch(e => console.error(e));
            img.org = ['org','org_webp'].map(k => ({key: k, value: `https://i.balaan.io/${cf.image_path}${img[k]}`}));
            img.org.forEach(i => this.getImgSize(i));

            let keys = 'jpg,webp,thumb_300,thumb_200'.split(',');
            img.arr = keys.map(k => ({key: k, value: `https://i.balaan.io/${cf.image_path}${img[k]}`}));
            img.arr.push({key: 'naver2', value: `https://ep-image.balaan.io/${cf.image_path}${img.jpg}?n=2`});
            img.arr.push({key: 'naver3', value: `https://ep-image.balaan.io/${cf.image_path}${img.jpg}?n=3`});
            img.arr.forEach(i => this.getImgSize(i));
          });
        });
      }
    },
    getImgSize(img) {
      let newImg = new Image();

      newImg.onload = () => {
        const height = newImg.height;
        const width = newImg.width;
        img.dim = {height, width};
        this.$forceUpdate();
      }

      newImg.src = img.value; // this must be done AFTER setting onload
      fetch(img.value).then(r => r.blob()).then(b => { img.size = b.size; this.$forceUpdate(); });
    }
  }
}
</script>
